<template>
  <header v-if="homepage.slideshow?.length || homepage.topSites?.length" class="bg-secondary-light">
    <section v-if="homepage.slideshow?.length" class="background-cut">
      <div class="max-w-screen-2xl mx-auto lg:px-12">
        <Slideshow :slides="homepage.slideshow" :navigation="true" :pagination="true" />
      </div>
    </section>
    <section v-if="homepage.topSites?.length" class="pt-7 max-w-screen-2xl mx-auto pl-6 lg:px-12">
      <TopSites :sites="homepage.topSites" />
    </section>
  </header>
</template>
<script setup lang="ts">
const homepage = useHomepage()
</script>
